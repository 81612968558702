/* src/index.css */
/* src/index.css */

body {
  font-family: "Consolas", monospace; /* Apply Consolas font to entire website */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: black; /* Example: Set background color */
  color: white; /* Example: Set text color */
}

/* Optionally, adjust other elements as needed */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Consolas", monospace; /* Apply Consolas font to headings */
}

p,
span,
div {
  font-family: "Consolas", monospace; /* Apply Consolas font to paragraphs, spans, and divs */
}

header {
  padding: 10px 20px;
}

header nav {
  display: flex;
  align-items: center; /* Center items vertically */
}

.nav-list {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center; /* Center items vertically */
  width: 100%; /* Occupy full width of nav */
}

.start-item {
  margin-right: auto; /* Adjust gap between image and links */
}

.icon {
  width: 40px;
  height: auto;
}

.nav-link {
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-link:hover {
  background-color: white;
  color: #6a0dad; /* Purple text on hover */
  text-decoration: none; /* Remove underline on hover */
}

.active {
  background-color: #6a0dad; /* Purple background for active item */
  color: white; /* White text for active item */
}

h1,
h2 {
  color: white;
}

a {
  color: #1e90ff;
}

a:hover {
  text-decoration: underline; /* Optional: Keep underline on other links */
}

div {
  padding: 20px;
}

img {
  max-width: 100%;
  height: auto;
}
section {
  padding: 60px 20px;
}
