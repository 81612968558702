/* src/style/Contact.css */

.contact-container {
  padding: 20px;
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  width: 100%;
  max-width: 500px; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
  color: white; /* White text for labels */
}

input,
textarea {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid white; /* White border for inputs */
  border-radius: 5px;
  background-color: transparent; /* Transparent background */
  color: white; /* White text */
  outline: none;
  width: 100%; /* Ensure input and textarea have the same width */
}

textarea {
  resize: none;
  height: 150px; /* Default height for the message box */
}

.submit-button {
  padding: 10px 20px;
  font-size: 1rem;
  border: 1px solid white; /* White border */
  border-radius: 5px;
  background-color: transparent; /* Transparent background */
  color: white; /* White text */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-top: 10px; /* Add space above the submit button */
}

.submit-button:hover {
  background-color: white; /* White background on hover */
  color: black; /* Black text on hover */
}
