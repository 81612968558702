/* src/style/About.css */

.about-container {
  padding: 60px 20px;
  background-color: #282c34; /* Dark background color */
  color: white;
}

.about-container h1 {
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 20px;
}

.about-content {
  display: flex;
  flex-direction: column; /* Make content column layout */
  justify-content: space-around;
  max-width: 1000px;
  margin: 0 auto;
}

.about-left,
.about-right {
  flex: 1;
}

.about-left p {
  text-align: left;
}

.skills-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.skills-table td {
  padding: 8px;
  border: 1px solid #ddd;
}

.skills-table td:first-child {
  font-weight: bold;
  width: 30%;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .about-content {
    flex-direction: row; /* Change to row layout for larger screens */
  }

  .about-right {
    text-align: left;
    margin-left: 20px;
  }
}
