/* Add this to your existing CSS file */

/* Ensure header is fixed, full width, and with a background color */
header {
  padding: 10px 20px;
  position: fixed;
  max-width: 90%;
  width: 90%;
  background-color: transparent;
  z-index: 1000; /* Ensure header is above other content */
}

/* Style for the hamburger icon */
.hamburger {
  display: none;
  cursor: pointer;
  color: white;
  font-size: 1.5em;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: black;
    position: absolute;
    top: 60px; /* Adjust based on header height */
    left: 0;
  }

  .nav-links.open {
    display: flex;
  }

  .hamburger {
    display: block;
  }

  .nav-link {
    padding: 15px;
    text-align: center;
    width: 100%;
    border-bottom: 1px solid white;
  }
}
