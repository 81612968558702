.projects-section {
  padding: 50px 20px;
  background-color: #111;
  color: #fff;
  text-align: center;
}

.section-title {
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #6a0dad; /* Adjust color to match your theme */
}

.projects-list {
  list-style-type: none;
  padding: 0;
  max-width: 1000px; /* Updated to make it wider */
  margin: 0 auto;
  text-align: left; /* Align text to the left within the list items */
}

.project-item {
  background-color: #222;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-title {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.project-techStack {
  font-size: 1em;
  margin-bottom: 10px;
}

.project-description {
  font-size: 1em;
  margin-bottom: 10px;
  list-style-type: disc;
  padding-left: 20px;
}

.project-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #6a0dad; /* Adjust color to match your theme */
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  margin-right: 10px; /* Add margin-right for spacing */
}

.project-link:hover {
  background-color: #8b00ff; /* Slightly lighter shade for hover effect */
}
.expand-icon {
  display: flex; /* Allow horizontal alignment */
  justify-content: flex-end; /* Align icon to the right */
  align-items: center; /* Vertically center icon */
}
