body {
  background-color: #000; /* Ensure the background color is black */
  color: #fff;
  font-family: "Courier New", Courier, monospace;
}

.timeline-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.timeline-heading {
  text-align: center;
  color: #fff;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.vertical-timeline-element--work {
  box-shadow: none !important;
}

.vertical-timeline-element-title {
  color: #fff;
}

.vertical-timeline-element-subtitle {
  color: #fff;
}

.vertical-timeline::before {
  background: #6a0dad !important; /* Ensure the timeline line is your accent color */
}
