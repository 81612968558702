/* Home.css */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto; /* Adjust as needed */
  background-color: black;
  color: white;
  padding: 20px; /* Added padding for spacing around the container */
}

.portfolio-image {
  max-width: 300px; /* Set a fixed max-width */
  width: 100%; /* Make the image responsive */
  height: auto; /* Maintain aspect ratio */
  margin-top: 20px; /* Adjust spacing */
  margin-bottom: 20px;
}

h1 {
  margin-bottom: 10px; /* Reduce space below h1 */
}

h2 {
  margin-top: 0; /* Remove default margin above h2 */
  margin-bottom: 20px; /* Adjust space below h2 */
}

.resume-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  border: 2px solid white;
  border-radius: 30px; /* Makes the button circular */
  background-color: transparent;
  color: white;
  text-decoration: none;
  font-family: "Consolas", monospace;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.resume-button:hover {
  background-color: white;
  color: black;
}
